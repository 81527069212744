// Imports => React
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';

// Imports => Atoms
import AcImage from '@atoms/ac-image/ac-image.web';

const _CLASSES = {
  MAIN: 'ac-light-box',
  WRAPPER: {
    MAIN: 'ac-light-box__wrapper',
    HIDDEN: 'ac-light-box__wrapper--hidden',
  },
  CLOSE: 'ac-light-box__close-icon',
  IMAGE: {
    MAIN: 'ac-light-box__image',
    PORTRAIT: 'ac-light-box__image--portrait',
    LANDSCAPE: 'ac-light-box__image--landscape',
    WRAPPER: 'ac-light-box__image-wrapper',
  },
  CONTENT: {
    MAIN: 'ac-light-box__content',
    HAS_NAVIGATION: 'ac-light-box__content--has-navigation',
    ITEM: 'ac-light-box__content__item',
  },
  NAVIGATION: {
    MAIN: 'ac-light-box__navigation',
    PREVIOUS: 'ac-light-box__navigation--previous',
    NEXT: 'ac-light-box__navigation--next',
  },
  ICON: {
    MAIN: 'ac-icon',
    LIGHTBOX: 'ac-light-box__icon',
    ARROW_LEFT: 'ac-icon--arrow-left',
    ARROW_RIGHT: 'ac-icon--arrow-right',
    CLOSE: 'ac-icon--close',
  },
  FOOTER: 'ac-light-box__footer',
  ITEM_COUNTER: 'ac-light-box__item-counter',
};

const getTransitionEvent = () => {
  let el = document.createElement('fakeelement');
  let transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  for (let t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
};

class AcLightBoxController extends React.Component {
  constructor(props) {
    super(props);

    const itemCount = props.items && props.items.length;

    this.wrapperRef = null;

    this.state = {
      currentItem: props.target || 0,
      itemCount,
      hasNavigation: itemCount > 1,
      visible: props.visible,
      buildLightBox: false,
    };

    this.transitionEvent = 'transitionend';

    this.addEvents = this.addEvents.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);

    this.transitionEnded = this.transitionEnded.bind(this);

    this.goToPreviousItem = this.goToPreviousItem.bind(this);
    this.goToNextItem = this.goToNextItem.bind(this);
    this.buildLightBox = this.buildLightBox.bind(this);
    this.closeLightBox = this.closeLightBox.bind(this);

    this.isVisible = this.isVisible.bind(this);
    this.getItemCounterText = this.getItemCounterText.bind(this);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.isVisible = this.isVisible.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.visible === prevProps.visible) return;

    if (!this.props.visible) return;

    const itemCount = this.props.items && this.props.items.length;

    this.setState({
      ...prevState,
      hasNavigation: itemCount > 1,
      itemCount,
      currentItem:
        typeof this.props.target !== 'undefined' && this.props.target !== null
          ? this.props.target
          : this.state.currentItem,
      visible: this.props.visible,
      buildLightBox: true,
    });
  }

  componentDidMount() {
    this.transitionEvent = getTransitionEvent();

    if (this.wrapperRef && this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener(
        this.transitionEvent,
        this.transitionEnded,
        false
      );
    }
  }

  componentWillUnMount() {
    document.removeEventListener('keyup', this.handleKeyUp, false);

    this.refs[_CLASSES.WRAPPER.MAIN].removeEventListener(
      this.transitionEvent,
      this.transitionEnded,
      false
    );
  }

  addEvents() {
    document.addEventListener('keyup', this.handleKeyUp, false);
  }

  handleKeyUp(event) {
    const { visible } = this.state;
    if (!visible) return;

    const key = event.key || event.which;

    if (key) {
      switch (key) {
        case 'Escape':
        case 27:
          this.closeLightBox();
          break;

        case 'ArrowLeft':
        case 37:
          this.goToPreviousItem();
          break;

        case 'ArrowRight':
        case 39:
          this.goToNextItem();
          break;
        default:
      }
    }
  }

  transitionEnded(event) {
    if (event) event.stopPropagation();
    if (!event.target.classList.contains(_CLASSES.WRAPPER.MAIN)) return false;
    if (!event.target.classList.contains(_CLASSES.IMAGE.MAIN)) return false;
    if (!event.target.classList.contains(_CLASSES.CONTENT.MAIN)) return false;

    const { visible, buildLightBox } = this.state;
    if (visible === buildLightBox) return;

    this.setState({
      buildLightBox: visible,
    });
  }

  goToPreviousItem = event => {
    if (event) event.stopPropagation();

    const { currentItem, itemCount } = this.state,
      targetItem = currentItem > 0 ? currentItem - 1 : itemCount - 1;

    this.setState({ currentItem: targetItem });
  };

  goToNextItem = event => {
    if (event) event.stopPropagation();

    const { currentItem, itemCount } = this.state,
      targetItem = currentItem < itemCount - 1 ? currentItem + 1 : 0;

    this.setState({ currentItem: targetItem });
  };

  getContent = () => {
    const { currentItem } = this.state;
    const { items } = this.props;

    const image = items.length > 0 && items[currentItem];

    if (!image) return false;

    return image.hasOwnProperty('mime_type') ? (
      <AcImage
        id={`ac-lightbox-image-${currentItem}`}
        type={'image'}
        source={image.responsive.xl ? image.responsive.xl : image.url}
        alt={image.label}
        className={this.getImageClassNames()}
        wrpClassName={this.getWrpClassNames()}
      />
    ) : (
      <Vimeo className="ac-light-box__vimeo" video={image.url} />
    );
  };

  buildLightBox = () => {
    const { buildLightBox } = this.state;

    if (buildLightBox) this.addEvents();

    return buildLightBox;
  };

  closeLightBox = event => {
    if (event) event.stopPropagation();
    const { closeCallback } = this.props;
    closeCallback && closeCallback();
  };

  isVisible = () => {
    const { visible } = this.state;
    return visible;
  };

  getItemCounterText = () => {
    const { currentItem, itemCount } = this.state;
    return `${currentItem + 1} - ${itemCount}`;
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
    return this.wrapperRef;
  };

  getIconClassNames = variant => {
    return clsx([
      _CLASSES.ICON.MAIN,
      variant && _CLASSES.ICON[variant.toUpperCase()],
      _CLASSES.ICON.LIGHTBOX,
    ]);
  };

  getCloseIconClassNames() {
    return clsx([_CLASSES.CLOSE]);
  }

  getNavigationItemClassNames(direction) {
    return clsx([
      _CLASSES.NAVIGATION.MAIN,
      direction && _CLASSES.NAVIGATION[direction.toUpperCase()],
    ]);
  }

  getItemCounterClassNames() {
    return clsx([_CLASSES.ITEM_COUNTER]);
  }

  getFooterClassNames() {
    return clsx([_CLASSES.FOOTER]);
  }

  getImageClassNames() {
    return clsx([_CLASSES.IMAGE.MAIN]);
  }

  getWrpClassNames() {
    return clsx([_CLASSES.IMAGE.WRAPPER]);
  }

  getContentItemClassNames() {
    return clsx([_CLASSES.CONTENT.ITEM]);
  }

  getContentClassNames() {
    const { hasNavigation } = this.state;
    return clsx([
      _CLASSES.CONTENT.MAIN,
      hasNavigation && _CLASSES.CONTENT.HAS_NAVIGATION,
    ]);
  }

  getWrapperStyleClassNames() {
    const { visible } = this.props;
    return clsx([_CLASSES.WRAPPER.MAIN, !visible && _CLASSES.WRAPPER.HIDDEN]);
  }

  getStyleClassNames() {
    return clsx([_CLASSES.MAIN]);
  }

  getWrapperStyleRefName() {
    return _CLASSES.WRAPPER.MAIN;
  }
}

AcLightBoxController.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closeCallback: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

AcLightBoxController.defaultProps = {
  visible: false,
};

export default AcLightBoxController;
