import React, { useCallback } from 'react';
import clsx from 'clsx';

import AcButton from '@atoms/ac-button/ac-button.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

/* eslint react-app/import/no-webpack-loader-syntax: off */
import Binoculars from '-!svg-react-loader!@assets/images/binoculars-white.svg';

const _CLASSES = {
  MAIN: 'ac-impersonate-bar',
  CONTAINER: {
    MAIN: 'ac-impersonate-bar__container',
  },
  BLOCK: {
    RIGHT: 'ac-impersonate-bar__block--right',
  },
  ICON: '',
  BUTTON: 'ac-impersonate-bar__button',
};

const AcImpersonateBar = ({
  impersonatingUser,
  className,
  exitImpersonation,
}) => {
  const getStyleClassNames = useCallback(props => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getContainerClassNames = useCallback(() => {
    return clsx([_CLASSES.CONTAINER.MAIN]);
  }, []);

  const getBlockClassNames = useCallback(() => {
    return clsx([_CLASSES.BLOCK.RIGHT]);
  }, []);

  const getButtonClassNames = useCallback(() => {
    return clsx([_CLASSES.BUTTON]);
  }, []);

  return (
    <div className={getStyleClassNames()}>
      <div className={getContainerClassNames()}>
        <Binoculars className={'ac-binoculars-logo'} />
        <div>
          Impersonate functie staat aan: &nbsp;
          <strong
            dangerouslySetInnerHTML={{ __html: impersonatingUser.name }}
          />
        </div>
        <div className={getBlockClassNames()}>
          <AcButton
            theme={'white'}
            variant={'outline'}
            callback={exitImpersonation}
            className={getButtonClassNames()}
          >
            STOP IMPERSONATE
            <AcRipple theme={'alpha'} size={'small'} />
          </AcButton>
        </div>
      </div>
    </div>
  );
};

export default AcImpersonateBar;
