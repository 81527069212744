// Imports => React
import React from 'react';

// Imports => Controller
import AcCarouselController from './ac-carousel.controller';

// Imports => Components
import AcCarouselItem from '@molecules/ac-carousel-item/ac-carousel-item.web.js';

// Imports => Atoms
import { AcColumn } from '@atoms/ac-grid';

class AcCarousel extends AcCarouselController {
  buildCarouselItems() {
    const { items } = this.state;

    return (
      items &&
      items.map((item, index) => {
        return (
          <div
            key={`key-inner-${item.id}`}
            ref={node => (item.el = node)}
            className={this.getInnerItemClassNames(item.type)}
          >
            {item.items &&
              item.items.map((innerItem, innerIndex) => {
                return (
                  <AcCarouselItem
                    key={`key-inner-item-${innerItem.id}`}
                    forwardRef={node => (innerItem.el = node)}
                    {...innerItem}
                    className={this.getInnerItemHolderClassNames()}
                    callback={this.handleCallback}
                  />
                );
              })}
          </div>
        );
      })
    );
  }

  render() {
    const { items } = this.state;
    return (
      <div className={this.getStyleClassNames()}>
        <div className={this.getWrapperClassNames()}>
          <div
            ref={this.getInnerRefName()}
            className={this.getInnerClassNames()}
            style={this.getInnerInlineStyles()}
          >
            {this.buildCarouselItems()}
          </div>
        </div>
        {items.length > 3 && (
          <AcColumn
            xs={12}
            sm={12}
            md={8}
            lg={8}
            className={this.getNavigationClassNames()}
          >
            <button
              aria-roledescription={'button'}
              className={this.getNavigationLeftClassNames()}
              disabled={!this.canGoLeft()}
              onClick={event => this.goToStep(event, 'previous')}
            >
              <i className="ac-icon ac-icon--chevron-left" />
            </button>
            <button
              aria-roledescription={'button'}
              className={this.getNavigationRightClassNames()}
              disabled={!this.canGoRight()}
              onClick={event => this.goToStep(event, 'next')}
            >
              <i className="ac-icon ac-icon--chevron-right" />
            </button>
          </AcColumn>
        )}
      </div>
    );
  }
}

export default AcCarousel;
