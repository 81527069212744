// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcStrengthIndicatorController from './ac-strength-indicator.controller.js';

class AcStrengthIndicator extends AcStrengthIndicatorController {
  render() {
    const { t } = this.props;
    const msg = this.getCleanedMessage();
    return (
      <div className={this.getStyleClassNames()}>
        <div className={this.getTrackerWrpClassNames()}>
          <div className={this.getTrackerClassNames()} />
        </div>
        {msg !== '' && (
          <div className={this.getMessageClassNames()}>{t(msg)}</div>
        )}
      </div>
    );
  }
}

export default withNamespaces()(AcStrengthIndicator);
