// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Import => Utilities
import AcUUID from '@utils/ac-uuid';

const _CLASSES = {
  MAIN: 'ac-text-input',
  DISABLED: 'ac-text-input--disabled',
  READONLY: 'ac-text-input--readonly',
  ERROR: 'ac-text-input--error',
  EMPTY: 'ac-text-input--empty',
  PLACEHOLDER: 'ac-text-input--placeholder',
  LABEL: {
    MAIN: 'ac-text-input__label',
    TITLE: 'ac-text-input__label__title',
  },
  INPUT: {
    MAIN: 'ac-text-input__field',
    PASSWORD: 'ac-text-input__field--password',
    TEXTAREA: 'ac-text-input__field--textarea',
  },
  VALIDATION: {
    ERROR: 'ac-text-input__error',
  },
  INSTRUCTIONS: 'ac-text-input__instructions',
  EYE: {
    MAIN: 'ac-text-input__eye',
    OPEN: 'ac-text-input__eye--open',
  },
};

// Controller
class AcTextInputController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: props.error,
      reference: AcUUID(),
      value: props.value,
      name: props.name,
      tabindex: props.tabindex || 0,
      type: props.type,
      eyeOpen: false,
    };

    this.onChange = this.onChange.bind(this);
    this.handleEyeClick = this.handleEyeClick.bind(this);
  }

  onChange(event) {
    const { validation } = this.props;

    const value = event && event.target.value;
    let hasError = false;

    if (validation) {
      hasError = validation(value);
    }

    this.setState(
      {
        hasError,
      },
      () => this.callback(event, value)
    );
  }

  callback(event, value) {
    const { name, type } = this.state;
    const { callback } = this.props;

    if (callback) callback(event, name, value, type);
  }

  handleEyeClick(event) {
    const { type } = this.state;

    const nextState = {
      eyeOpen: type === 'password',
      type: type === 'password' ? 'text' : 'password',
    };

    this.setState({ eyeOpen: nextState.eyeOpen, type: nextState.type });
  }

  getPlaceholder() {
    const { placeholder, t } = this.props;
    return t(placeholder) || undefined;
  }

  getInputClassNames() {
    const { type } = this.props;

    return clsx(
      _CLASSES.INPUT.MAIN,
      type && type === 'password' && _CLASSES.INPUT.PASSWORD,
      type && type === 'textarea' && _CLASSES.INPUT.TEXTAREA
    );
  }

  getLabelClassNames() {
    return clsx(_CLASSES.LABEL.MAIN);
  }

  getLabelTitleClassNames() {
    return clsx(_CLASSES.LABEL.TITLE);
  }

  getErrorClassNames() {
    return clsx(_CLASSES.VALIDATION.ERROR);
  }

  getInstructionsClassNames() {
    return clsx(_CLASSES.INSTRUCTIONS);
  }

  getEyeClassNames() {
    const { eyeOpen } = this.state;
    return clsx(_CLASSES.EYE.MAIN, eyeOpen && _CLASSES.EYE.OPEN);
  }

  getStyleClassNames() {
    const { value, disabled, readonly, className, placeholder } = this.props;
    const { hasError } = this.state;

    return clsx(
      _CLASSES.MAIN,
      disabled && _CLASSES.DISABLED,
      readonly && _CLASSES.READONLY,
      !value && _CLASSES.EMPTY,
      hasError && _CLASSES.ERROR,
      placeholder && _CLASSES.PLACEHOLDER,
      className
    );
  }
}

const requiredPropsCheck = (props, propName, componentName) => {
  if (
    !props.value &&
    props.value !== '' &&
    !props.placeholder &&
    props.placeholder !== ''
  ) {
    return new Error(
      `At least one of the props 'value' or 'placeholder' is required by '${componentName}' component.`
    );
  }
};

AcTextInputController.propTypes = {
  callback: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'number',
    'tel',
    'email',
    'password',
    'textarea',
  ]),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  validation: PropTypes.func,
  autocomplete: PropTypes.bool,
  instructions: PropTypes.string,
  error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
  value: requiredPropsCheck,
  placeholder: requiredPropsCheck,
  indicator: PropTypes.bool,
  eye: PropTypes.bool,
};

AcTextInputController.defaultProps = {
  type: 'text',
  autocomplete: false,
  disabled: false,
  readonly: false,
  indicator: false,
  eye: false,
};

export default AcTextInputController;
