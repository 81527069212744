// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-stepper',
  HORIZONTAL: 'ac-stepper---horizontal',
  ALIGNMENT: {
    DEFAULT: 'ac-stepper--align-default',
    TOP: 'ac-stepper--align-start',
    BOTTOM: 'ac-stepper--align-end',
  },
};

class AcStepperController extends React.Component {
  constructor(props) {
    super(props);

    this.el = null;

    this.state = {
      divider: {
        styles: props.steps
          .slice(0, props.steps.length - 1)
          .map(step => ({ width: 'auto', offset: 'auto' })),
      },
    };

    this.handleCallback = this.handleCallback.bind(this);
    this.calculateDividerSize = this.calculateDividerSize.bind(this);
  }

  componentDidMount() {
    const { steps } = this.props;

    if (steps.length > 1) {
      window.addEventListener('resize', this.calculateDividerSize, false);
      this.calculateDividerSize();
    }
  }

  componentWillUnmount() {
    const { steps } = this.props;

    if (steps.length > 1) {
      window.removeEventListener('resize', this.calculateDividerSize, false);
    }
  }

  handleCallback(event, step) {
    const { interactive, callback } = this.props;

    if (interactive) return;

    if (callback) callback(event, step);
  }

  calculateDividerSize() {
    const { horizontal } = this.props;

    const $container = this.el;
    const $children = $container.getElementsByClassName('ac-step');
    const $titles = $container.getElementsByClassName('ac-step__title');
    const $icons = $container.getElementsByClassName('ac-step__icon');

    let styles = [];

    for (let x = 0; x < $children.length - 1; x++) {
      let _this = $icons[x];
      let _next = $icons[x + 1];
      let _title = $titles[x];

      let rect = {
        _this: _this.getBoundingClientRect(),
        _next: _next.getBoundingClientRect(),
        _title: _title.getBoundingClientRect(),
      };

      let width = 0;
      let offset = 0;

      if (horizontal) {
        width = rect._next.left - rect._this.left;
        width -= rect._title.width + 10;
        offset = `${rect._title.width - 10}px`;
      } else {
        width = rect._next.left - rect._this.left;
      }

      width -= rect._this.width;

      styles.push({
        width: width,
        offset: offset,
      });
    }

    this.setState({
      divider: {
        styles: styles,
      },
    });
  }

  getStyleClassNames() {
    const { titlePlacement, horizontal, className } = this.props;

    return clsx(
      _CLASSES.MAIN,
      titlePlacement && _CLASSES.ALIGNMENT[titlePlacement.toUpperCase()],
      horizontal && _CLASSES.HORIZONTAL,
      className && className
    );
  }
}

AcStepperController.propTypes = {
  steps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  interactive: PropTypes.bool,
  indexes: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'medium', 'small']),
  titlePlacement: PropTypes.oneOf(['default', 'top', 'bottom']),
  horizontal: PropTypes.bool,
  callback: PropTypes.func,
};

AcStepperController.defaultProps = {
  indexes: false,
  size: 'default',
  titlePlacement: 'default',
  horizontal: false,
};

export default AcStepperController;
