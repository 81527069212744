// Imports => React
import React from 'react';
import { NavLink } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { inject, observer } from 'mobx-react';

// Imports => Controller
import AcNavigationController from './ac-navigation.controller.js';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

@inject('routing', 'store')
@observer
class AcNavigation extends AcNavigationController {
  render() {
    const { routes, indicator } = this.state;
    const { indicate, t } = this.props;

    return (
      <nav
        ref={node => (this.state.el = node)}
        className={this.getStyleClassNames()}
      >
        <ul className={this.getNavListClassNames()}>
          {routes &&
            routes.map(route => {
              return (
                <li
                  key={route.id}
                  ref={node => (route.ref = node)}
                  className={this.getNavListItemClassNames()}
                >
                  {route.path && (
                    <NavLink
                      to={route.path}
                      onClick={event => {
                        this.handleClickEvent(event, route);
                      }}
                      className={this.getNavLinkClassNames(route)}
                      activeClassName={this.getNavLinkClassNames(
                        route,
                        'active'
                      )}
                      isActive={this.isActive}
                      title={t(route.title)}
                      exact
                      strict
                    >
                      {typeof route.label === 'string' && t(route.label)}
                      {typeof route.label !== 'string' && route.label}
                      <AcRipple simple={true} />
                    </NavLink>
                  )}
                  {!route.path && (
                    <span
                      onClick={event => {
                        this.handleClickEvent(event, route);
                      }}
                      className={this.getNavLinkClassNames(route)}
                    >
                      {typeof route.label === 'string' && t(route.label)}
                      {typeof route.label !== 'string' && route.label}
                      <AcRipple simple={true} />
                    </span>
                  )}
                </li>
              );
            })}
        </ul>
        {indicate && (
          <span
            ref={node => (this.state.indicator.el = node)}
            className={this.getIndicatorClassNames()}
            style={indicator.position}
          />
        )}
      </nav>
    );
  }
}

export default withNamespaces()(AcNavigation);
