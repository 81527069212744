// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';

// Imports => Utilities
import AcUUID from '@utils/ac-uuid';

// Imports => Controller
import AcSwitchController from './ac-switch.controller';

// Component
class AcSwitch extends AcSwitchController {
  renderSwitchOptions = () => {
    const { name, options, t } = this.props;

    return options.map(option => {
      if (!option.id) option.id = AcUUID();
      return (
        <label
          htmlFor={`${name}-${option.id}`}
          key={option.id}
          ref={node => (option.el = node)}
          className={this.getOptionClassNames(option)}
          onClick={event => {
            this.handleChangeEvent(event, option);
          }}
        >
          <input
            type={'radio'}
            value={option.value}
            name={name}
            id={`${name}-${option.id}`}
            defaultChecked={this.isChecked(option)}
            className={this.state._CLASSES.OPTION.INPUT}
          />
          <span className={this.state._CLASSES.OPTION.LABEL}>
            {t(option.label)}
          </span>
        </label>
      );
    });
  };

  renderSwitch = () => {
    return (
      <div
        className={this.getStyleClassNames()}
        ref={node => (this.state.el = node)}
      >
        <div className={this.getSlotClassNames()}>
          <div
            ref={node => (this.state.tracker.el = node)}
            className={this.getTrackerClassNames()}
            style={this.getTrackerPosition()}
          />
          {this.renderSwitchOptions()}
        </div>
      </div>
    );
  };

  render() {
    return this.renderSwitch();
  }
}

export default withNamespaces()(AcSwitch);
