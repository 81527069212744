import React from 'react';

// imports => Assets
import AcLogoFullWhiteSVG from '@assets/images/aureus-logo-1x.svg';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';

const AcPartnerFooter = () => {
  return (
    <>
      <AcSection theme={'pitch'} className={'ac-partner-footer'}>
        <AcContainer>
          <AcRow>
            <AcColumn xs={12} sm={{ size: 10, offset: 1 }}>
              <AcLogoFullWhiteSVG className={'ac-partner-footer__logo'} />
            </AcColumn>
          </AcRow>
          <hr className="devision-line" />
        </AcContainer>
      </AcSection>
    </>
  );
};

export default AcPartnerFooter;
