// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcToasterController from './ac-toaster.controller.js';

@inject('routing', 'store')
@observer
class AcToaster extends AcToasterController {
  render() {
    const { id, title, description, code, closeable } = this.props;

    const excludePathNames = ['/login', '/register'];

    const excludeToasterOnPathNames = array => {
      for (let index = 0; index < array.length; index++) {
        if (window.location.pathname.indexOf(array) !== -1) {
          return true;
        }
        return false;
      }
    };
    return (
      excludeToasterOnPathNames(excludePathNames) && (
        <div className={this.getStyleClassNames()} id={id}>
          <div className={this.getBodyClassNames()}>
            <div className={this.getIconWrpClassNames()}>
              <i className={this.getIconClassNames()} />
            </div>

            <div className={this.getContentWrpClassNames()}>
              {title && (
                <div className={this.getTitleClassNames()}>{title}</div>
              )}
              {description && (
                <div
                  className={this.getDescriptionClassNames()}
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              )}

              {code && (
                <div className={this.getCodeClassNames()}>
                  [Error code: {code}]
                </div>
              )}
            </div>
            {closeable && (
              <div
                className={this.getCloseWrpClassNames()}
                onClick={this.close}
              >
                <div className={this.getCloseIconClassNames()} />
              </div>
            )}
          </div>
          <div
            className={this.getProgressClassNames()}
            style={this.getStyleProperties()}
          />
        </div>
      )
    );
  }
}

export default withNamespaces()(AcToaster);
