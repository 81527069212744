// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-user-navigation',
  VISIBLE: 'ac-user-navigation--visible',
  TOGGLE: {
    MAIN: 'ac-user-navigation__toggle',
    ICON: 'ac-user-navigation__toggle-icon',
    LABEL: 'ac-user-navigation__toggle-label',
    POINTER: 'ac-user-navigation__toggle-pointer',
  },
  LIST: {
    MAIN: 'ac-user-navigation__list',
    ITEM: 'ac-user-navigation__item',
  },
  LINK: {
    MAIN: 'ac-user-navigation__link',
    ACTIVE: 'ac-user-navigation__link--active',
    ICON: 'ac-user-navigation__link-icon',
  },
};

class AcUserNavigationController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;
    this.routing = props.routing;

    this.$navigation = React.createRef();

    this.state = {};

    this.init = this.init.bind(this);
    this.hide = this.hide.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hide, false);
  }

  init() {
    document.addEventListener('click', this.hide, false);
  }

  hide(event) {
    if (event && event.persist) event.persist();
    if (event && event.target) {
      const $navigation = this.$navigation.current;

      if ($navigation) {
        const inside = $navigation.contains(event.target);

        if (!inside) {
          this.store.ui.setState('user_navigation', 'visible', false);
        }
      }
    }
  }

  handleToggle(event) {
    if (event && event.preventDefault) event.preventDefault();

    this.store.ui.setState(
      'user_navigation',
      'visible',
      !this.store.ui['user_navigation']['visible']
    );
  }

  handleClick(event, route) {
    if (event && event.preventDefault) event.preventDefault();
    const { push } = this.routing;

    if (push && route) push(route);
  }

  handleLogout = async event => {
    if (event && event.preventDefault) event.preventDefault();
    const { replace } = this.routing;

    this.store.ui.setState('user_navigation', 'visible', false);
    await this.store.auth.logout();
    if (replace) replace('/login');
  };

  getLinkIconClassNames() {
    return clsx(_CLASSES.LINK.ICON);
  }

  getLinkClassNames(route) {
    const {
      routing: { location },
    } = this.props;

    let active = false;
    if (location && route) {
      active = location.pathname === route.path;
    }

    return clsx(_CLASSES.LINK.MAIN, active && _CLASSES.LINK.ACTIVE);
  }

  getListItemClassNames() {
    return clsx(_CLASSES.LIST.ITEM);
  }

  getListClassNames() {
    return clsx(_CLASSES.LIST.MAIN);
  }

  getToggleLabelClassNames() {
    return clsx(_CLASSES.TOGGLE.LABEL);
  }

  getPointerIconClassNames() {
    return clsx(_CLASSES.TOGGLE.POINTER);
  }

  getToggleIconClassNames() {
    return clsx(_CLASSES.TOGGLE.ICON);
  }

  getToggleClassNames() {
    return clsx(_CLASSES.TOGGLE.MAIN);
  }

  getStyleClassNames() {
    return clsx(
      _CLASSES.MAIN,
      this.store.ui['user_navigation']['visible'] && _CLASSES.VISIBLE
    );
  }
}

AcUserNavigationController.propTypes = {};

AcUserNavigationController.defaultProps = {};

export default AcUserNavigationController;
