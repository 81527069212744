// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Imports => Utils
import AcUUID from '@utils/ac-uuid.js';

const _CLASSES = {
  MAIN: 'ac-carousel',
  INFINITE: 'ac-carousel--infinite',
  WRAPPER: 'ac-carousel__wrapper',
  INNER: {
    MAIN: 'ac-carousel__inner',
    BLOCK_ANIMATION: 'ac-carousel__inner--block-animation',
    ITEM: {
      MAIN: 'ac-carousel__inner__item',
      HOLDER: 'ac-carousel__inner__item--holder',
      SINGLE: 'ac-carousel__inner__item--single',
      DOUBLE: 'ac-carousel__inner__item--double',
    },
  },
  NAVIGATION: {
    MAIN: 'ac-carousel__navigation',
    LEFT: 'ac-carousel__navigation--left',
    RIGHT: 'ac-carousel__navigation--right',
    DISABLED: 'ac-carousel__navigation--disabled',
  },
};

let timer = null;

class AcCarouselController extends React.Component {
  constructor(props) {
    super(props);

    const items = this.parseCarouselItems(props.items);

    this.state = {
      items,
      currentStep: 0,
      currentOffset: 0,
      maxStep: items.length,
    };

    this.goToStep = this.goToStep.bind(this);
    this.goToInfinityStep = this.goToInfinityStep.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount() {
    if (timer) clearTimeout(timer);
    const items = this.parseCarouselItems(this.props.items);
    this.setState({ items }, () => {
      if (!this.props.infinite && items.length > 6) {
        timer = setTimeout(() => {
          const currentStep = items.length - 1;
          this.goToStep(null, 'next', currentStep, true);
        }, 3000);
      }
    });
  }

  handleCallback(event, label, id) {
    const { callback } = this.props;

    if (callback) callback(event, label, id);
  }

  parseCarouselItems(items) {
    let clone = items.slice(0);
    let result = [];
    let buildSingular = true;

    for (let i = 0; i < clone.length; i++) {
      const found = clone.splice(0, buildSingular ? 1 : 2),
        width = buildSingular ? 450 : 300,
        height = buildSingular ? 385 : 192.5;

      found.forEach(item => {
        if (!item.id) item.id = AcUUID();
        item.width = width;
        item.height = height;
      });

      result.push({
        id: AcUUID(),
        type: buildSingular ? 'single' : 'double',
        width,
        items: found,
      });
      buildSingular = !buildSingular;
      i = -1;
    }

    if (this.props.infinite) {
      const half_length = Math.floor(result.length / 2);
      const leftSide = result.splice(0, half_length);
      const rightSide = result;

      result = [...rightSide, ...leftSide];
    }

    return result;
  }

  goToStep(event, direction, index, initial) {
    if (event && event.stopPropagation) event.stopPropagation();

    const { infinite, callback } = this.props;
    const { currentStep, currentOffset, items } = this.state;

    if (
      event &&
      event.currentTarget &&
      event.currentTarget.className.includes(_CLASSES.NAVIGATION.DISABLED)
    )
      return;

    // Trigger scroll event
    const scroller = document.getElementById('ac-scroller');
    scroller.dispatchEvent(new Event('scroll'));

    const toNextStep = direction === 'next' ? true : false;
    const targetStep = index
      ? index
      : toNextStep
      ? currentStep + 1
      : currentStep - 1;

    if (infinite) {
      return this.goToInfinityStep(toNextStep).then(offset => {
        this.setState(
          {
            currentStep: targetStep,
            blockAnimations: false,
            currentOffset: offset,
          },
          () => {
            if (callback) callback(event, targetStep);
          }
        );
      });
    }

    if (items && items[targetStep]) {
      if (!this.refs[_CLASSES.INNER.MAIN]) return;

      const targetStepWidth = items[targetStep].width;
      const maxOffset = -Math.abs(
        this.refs[_CLASSES.INNER.MAIN].offsetWidth - window.innerWidth
      );

      let currentMaxOffset = initial ? maxOffset : currentOffset;
      let targetOffset = toNextStep
        ? currentMaxOffset - targetStepWidth
        : currentMaxOffset + targetStepWidth;

      if (targetOffset > 0) targetOffset = 0;
      if (targetOffset < maxOffset) targetOffset = maxOffset;

      if (toNextStep) targetOffset -= 60;

      this.setState(
        { currentStep: targetStep, currentOffset: targetOffset },
        () => {
          if (callback) callback(event, targetStep);
        }
      );
    }
  }

  goToInfinityStep(toNextStep) {
    return new Promise(resolve => {
      const { items } = this.state;
      const clone = items.slice(0);

      let targetOffset;
      let item;

      if (toNextStep) {
        targetOffset = Math.abs(clone[0].width); // padding...

        item = clone.shift();
        clone.push(item);
      } else {
        targetOffset = -Math.abs(clone[clone.length - 1].width);

        item = clone.pop();
        clone.unshift(item);
      }

      this.setState({
        items: clone,
        blockAnimations: true,
        currentOffset: targetOffset,
      });

      setTimeout(() => {
        resolve(0);
      }, 25); // Give the state a little time to settle.
    });
  }

  canGoLeft() {
    const { currentStep } = this.state;
    const { infinite } = this.props;

    return infinite || currentStep > 0;
  }

  canGoRight() {
    const { currentStep, maxStep } = this.state;
    const { infinite } = this.props;

    return infinite || currentStep < maxStep - 1;
  }

  getInnerRefName() {
    return _CLASSES.INNER.MAIN;
  }

  getInnerInlineStyles() {
    const { currentOffset } = this.state;

    return {
      transform: `translate(${currentOffset / 10}rem, 0)`,
    };
  }

  getStyleClassNames() {
    const { infinite, className } = this.props;
    return clsx(
      _CLASSES.MAIN,
      infinite && _CLASSES.INFINITE,
      className && className
    );
  }

  getWrapperClassNames() {
    return clsx(_CLASSES.WRAPPER);
  }

  getInnerClassNames() {
    const { blockAnimations } = this.state;

    return clsx(
      _CLASSES.INNER.MAIN,
      blockAnimations && _CLASSES.INNER.BLOCK_ANIMATION
    );
  }

  getInnerItemClassNames(type) {
    return clsx(
      _CLASSES.INNER.ITEM.MAIN,
      type && _CLASSES.INNER.ITEM[type.toUpperCase()]
    );
  }

  getInnerItemHolderClassNames() {
    return clsx(_CLASSES.INNER.ITEM.HOLDER);
  }

  getNavigationClassNames() {
    return clsx(_CLASSES.NAVIGATION.MAIN);
  }

  getNavigationLeftClassNames() {
    return clsx(
      _CLASSES.NAVIGATION.LEFT,
      !this.canGoLeft() && _CLASSES.NAVIGATION.DISABLED
    );
  }

  getNavigationRightClassNames() {
    return clsx(
      _CLASSES.NAVIGATION.RIGHT,
      !this.canGoRight() && _CLASSES.NAVIGATION.DISABLED
    );
  }
}

AcCarouselController.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  infinite: PropTypes.bool,
  callback: PropTypes.func,
};

AcCarouselController.defaultProps = {
  infinite: false,
};

export default AcCarouselController;
