// Imports => React
import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { withNamespaces } from 'react-i18next';

// Imports => Utilities
import AsideNavigation from '@utils/ac-asidenavigation';

const _CLASSES = {
  MAIN: 'ac-aside-navigation',
  LIST: {
    MAIN: 'ac-aside-navigation__list',
    ITEM: 'ac-aside-navigation__list__item',
    LINK: {
      MAIN: 'ac-aside-navigation__list__link',
      ACTIVE: 'ac-aside-navigation__list__link--active',
    },
  },
};

const AcAsideNavigation = ({ anchors, t }) => {
  let $navigation = React.createRef();
  let AsideNavigationInstance = null;

  useEffect(() => {
    initAsideNavigationBehaviour();

    return () => {
      unloadAsideNavigationBehaviour();
    };
  }, [anchors]);

  const unloadAsideNavigationBehaviour = useCallback(() => {
    if (AsideNavigationInstance !== null) {
      AsideNavigationInstance.unload();
    }
  }, [AsideNavigationInstance]);

  const initAsideNavigationBehaviour = useCallback(() => {
    const scroller = document.getElementById('ac-scroller');
    const container = document.querySelector('.ac-scroll-container');
    const target = $navigation.current;

    AsideNavigationInstance = new AsideNavigation(
      target,
      container,
      scroller,
      anchors,
      _CLASSES.LIST.LINK.ACTIVE
    );
  }, [$navigation]);

  const getListLinkClassNames = useCallback(anchor => {
    return clsx(
      _CLASSES.LIST.LINK.MAIN,
      anchor && anchor.active && _CLASSES.LIST.LINK.ACTIVE
    );
  }, []);

  const getListItemClassNames = useCallback(() => {
    return clsx(_CLASSES.LIST.ITEM);
  }, []);

  const getListClassNames = useCallback(() => {
    return clsx(_CLASSES.LIST.MAIN);
  }, []);

  const getStyleClassNames = useCallback(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  const getStyleProperties = useCallback(() => {
    return {};
  }, []);

  return (
    <div
      ref={$navigation}
      className={getStyleClassNames()}
      style={getStyleProperties()}
    >
      <ul className={getListClassNames()}>
        {anchors &&
          anchors.map(anchor => {
            return (
              <li key={anchor.id} className={getListItemClassNames()}>
                <a
                  href={`#${anchor.rel}`}
                  ref={node => (anchor.reference = node)}
                  rel={anchor.rel}
                  className={getListLinkClassNames(anchor)}
                >
                  {t(anchor.label)}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default withNamespaces()(React.memo(AcAsideNavigation));
