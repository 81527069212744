import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import Vimeo from '@u-wave/react-vimeo';
import clsx from 'clsx';
import { Navigation } from 'swiper';

import { inject, observer } from 'mobx-react';

const AcSwiper = ({ items, wrap = false, stacked = false, store }) => {
  const _CLASSES = {
    root: clsx('ac-swiper', {
      'ac-swiper--wrap': wrap,
      'ac-swiper--stacked': stacked,
    }),
    image: clsx('ac-swiper__image'),
    vimeo: clsx('ac-swiper__vimeo'),
  };

  // console.clear();

  const _items = items.map((item, index) => ({ ...item, idx: index }));
  let carouselItems = _items;

  if (stacked) {
    carouselItems = _items.reduce((acc, item, index) => {
      const stack = index % 3 === 2;
      if (stack) {
        acc[acc.length - 1].push(item);
      } else {
        acc.push([item]);
      }
      return acc;
    }, []);
  }

  const clickHandler = async id => {
    await store.ui.setLightboxTarget(id);
    await store.ui.setLightboxCollection(_items);
    store.ui.openLightbox();
  };

  const getSlideItem = (item, key, idx) => {
    if (!item) return null;

    const rest = {};
    let result = null;

    if (item && item.type === 'image') {
      if (item.update && item.update.title) {
        rest['data-rel-update'] = item.update.title || 'unknown';
        rest['data-rel-pub'] = item.update.published || 'unknown';
      }

      if (item.created_at) {
        rest['data-rel-created-at'] = item.created_at || 'unknown';
      }

      result = (
        <img
          className={_CLASSES.image}
          onClick={() => clickHandler(idx)}
          key={key ? key : null}
          alt={item.name}
          src={item.url}
          loading="lazy"
          {...rest}
        />
      );
    } else if (item && item.type === 'video') {
      if (item.update && item.update.title) {
        rest['data-rel-update'] = item.update.title || 'unknown';
        rest['data-rel-pub'] = item.update.published || 'unknown';
      }

      result = (
        <div
          key={key ? key : null}
          className={_CLASSES.vimeo}
          onClick={() => clickHandler(idx)}
          {...rest}
        >
          <Vimeo
            video={item.url}
            showPortrait={false}
            showTitle={false}
            showByline={false}
            pip={false}
            color={'#fc5a10'}
          />
        </div>
      );
    }

    return result;
  };

  const getSlideClasses = content => {
    return clsx('ac-swiper__slide', {
      'ac-swiper__slide--stacked':
        Array.isArray(content) && content.length === 2,
    });
  };

  if (!carouselItems || !carouselItems.length) return null;
  return (
    <>
      <Swiper
        className={_CLASSES.root}
        modules={[Navigation]}
        navigation
        slidesPerView={'auto'}
        spaceBetween={stacked ? 12 : 0}
        centeredSlides={!stacked}
        centeredSlidesBounds={!stacked}
      >
        {carouselItems.map((content, id) => {
          return (
            <SwiperSlide key={id} className={getSlideClasses(content)}>
              {Array.isArray(content)
                ? content.map((item, key) => getSlideItem(item, key, item.idx))
                : getSlideItem(content, id, content.idx)}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default inject('store')(observer(AcSwiper));
