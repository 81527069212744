// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcTextInputController from './ac-text-input.controller';

// Imports => Atoms
import AcStrengthIndicator from '@atoms/ac-strength-indicator/ac-strength-indicator.web.js';

// Component
class AcTextInput extends AcTextInputController {
  buildError() {
    const { t } = this.props;
    const { hasError } = this.state;
    return <div className={this.getErrorClassNames()}>{t(hasError)}</div>;
  }

  buildInstructions() {
    const { instructions, t } = this.props;
    return (
      <div className={this.getInstructionsClassNames()}>{t(instructions)}</div>
    );
  }

  buildTextInput() {
    const { value, disabled, readonly, autocomplete } = this.props;
    const { name, reference, type, tabindex } = this.state;

    if (type === 'textarea') {
      return (
        <textarea
          type={type}
          name={name}
          id={reference}
          value={value}
          placeholder={this.getPlaceholder()}
          onChange={event => this.onChange(event)}
          className={this.getInputClassNames()}
          disabled={disabled}
          readOnly={readonly}
          autoComplete={autocomplete ? 'on' : 'off'}
          tabIndex={tabindex}
        />
      );
    }

    return (
      <input
        type={type}
        name={name}
        id={reference}
        value={value}
        placeholder={this.getPlaceholder()}
        onChange={event => this.onChange(event)}
        className={this.getInputClassNames()}
        disabled={disabled}
        readOnly={readonly}
        autoComplete={autocomplete ? 'on' : 'off'}
        tabIndex={tabindex}
      />
    );
  }

  render() {
    const {
      label,
      type,
      value,
      disabled,
      readonly,
      instructions,
      indicator,
      eye,
      t,
    } = this.props;
    const { hasError, reference } = this.state;

    return (
      <div
        ref={node => (this.el = node)}
        aria-roledescription={'input'}
        aria-labelledby={`label-${reference}`}
        className={this.getStyleClassNames()}
        disabled={disabled}
        readOnly={readonly}
      >
        {this.buildTextInput()}
        <label
          id={`label-${reference}`}
          htmlFor={reference}
          className={this.getLabelClassNames()}
        >
          {t(label)}
        </label>
        {hasError && this.buildError()}
        {!hasError && instructions && this.buildInstructions()}
        {type === 'password' && indicator && (
          <AcStrengthIndicator value={value} />
        )}
        {type === 'password' && eye && (
          <span
            className={this.getEyeClassNames()}
            onClick={this.handleEyeClick}
          />
        )}
      </div>
    );
  }
}

export default withNamespaces()(AcTextInput);
