// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';

const _CLASSES = {
  MAIN: 'ac-transaction',
  COLUMN: {
    MAIN: 'ac-transaction__column',
    FIXED: 'ac-transaction__column--fixed',
    FLUID: 'ac-transaction__column--fluid',
    AMOUNT: 'ac-transaction__column--amount',
  },
  AMOUNT: {
    MAIN: 'ac-transaction__amount',
    WITH_TAXES: 'ac-transaction__amount--with_taxes',
    POSITIVE: 'ac-transaction__amount--positive',
    NEGATIVE: 'ac-transaction__amount--negative',
  },
  LABEL: {
    MARGIN: 'h-margin-right-10',
    FONT_SIZE: 'h-text--size-tiny',
    FONT_WEIGHT: 'h-text--semibold',
    TEXT_TRANSFORM: 'h-text--tt-uppercase',
    TEXT_COLOUR: 'h-text--color-medium',
  },
};

const AcTransaction = ({
  date,
  sign,
  symbol,
  amount,
  description,
  to,
  t,
  transaction,
}) => {
  const getAmountClassNames = amount => {
    let am = parseFloat(amount);
    let modifier = am < 0 ? 'NEGATIVE' : am > 0 ? 'POSITIVE' : false;

    return clsx(_CLASSES.AMOUNT.MAIN, modifier && _CLASSES.AMOUNT[modifier]);
  };

  const getLabelClassNames = () => {
    return clsx(
      _CLASSES.LABEL.MARGIN,
      _CLASSES.LABEL.FONT_SIZE,
      _CLASSES.LABEL.FONT_WEIGHT,
      _CLASSES.LABEL.TEXT_TRANSFORM,
      _CLASSES.LABEL.TEXT_COLOUR
    );
  };

  const getColumnClassNames = className => {
    return clsx(_CLASSES.COLUMN.MAIN, className && className);
  };

  const getStyleClassNames = () => {
    return clsx(_CLASSES.MAIN);
  };

  const getAmountWrapperClassNames = () => clsx(_CLASSES.COLUMN.AMOUNT);

  // Date order breaking on staging and production. The API always returns the oldest date first so Array.reverse is used to flip the order to show the latest/newest dat first

  return transaction.reverse().map((data, id) => (
    <div key={id} className={getStyleClassNames()}>
      <AcRow>
        <AcColumn
          xs={6}
          sm={{ size: 3, offset: 0 }}
          md={{ size: 3, offset: 0 }}
          className={getColumnClassNames()}
        >
          <span className={getLabelClassNames()}>{t('Datum')}</span>
          <span className="transaction-date">{data.date}</span>
        </AcColumn>
        {data.amount_with_taxes !== data.amount && (
          <AcColumn
            xs={6}
            sm={{ size: 3, offset: 3 }}
            md={{ size: 3, offset: 3 }}
            className={getColumnClassNames()}
          >
            <div
              className={getAmountWrapperClassNames()}
              style={{ marginTop: 'auto' }}
            >
              <span
                className={`${getAmountClassNames()} ${
                  _CLASSES.AMOUNT.WITH_TAXES
                }`}
              >
                {data.symbol} {data.amount_with_taxes}
              </span>
            </div>
          </AcColumn>
        )}
        <AcColumn
          xs={6}
          sm={
            data.amount_with_taxes !== data.amount
              ? { size: 3, offset: 0 }
              : { size: 3, offset: 6 }
          }
          md={
            data.amount_with_taxes !== data.amount
              ? { size: 3, offset: 0 }
              : { size: 3, offset: 6 }
          }
          className={getColumnClassNames()}
        >
          <div className={getAmountWrapperClassNames()}>
            <span className={getLabelClassNames()}>{t('Bedrag')}</span>
            <span className={getAmountClassNames(data.amount)}>
              {data.symbol} {data.amount}
            </span>
          </div>
        </AcColumn>
      </AcRow>
    </div>
  ));
};

export default withNamespaces()(React.memo(AcTransaction));
