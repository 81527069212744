// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-hero',
  MAIN_NO_FADE: 'ac-hero--no-fade',
  LOADED: 'ac-hero--loaded',
  BACKGROUND: {
    MAIN: 'ac-hero__background',
    WRAP: 'ac-hero__background-wrp',
  },
  OVERLAY: {
    MAIN: 'ac-hero__overlay',
  },
  INNER: {
    MAIN: 'ac-hero__inner',
    TITLE: 'ac-hero__title',
    SUBTITLE: 'ac-hero__subtitle',
    BUTTON: 'ac-hero__back-button',
  },
};

class AcHeroController extends React.Component {
  constructor(props) {
    super(props);

    this._timer = null;
    this._delay = props.routing.history.action === 'PUSH' ? 1000 : 0;

    this.el = false;
    this.background = {
      el: false,
      wrp: false,
    };

    this.state = {
      loaded: false,
      background: {
        styles: '',
      },
    };
  }

  componentDidMount() {
    clearTimeout(this._nectar_delay);
    clearTimeout(this._timer);
  }

  componentWillUnmount() {
    clearTimeout(this._nectar_delay);
    clearTimeout(this._timer);
  }

  handleBackButtonCallback = (event, el) => {
    const { backButton } = this.props;
    const { goBack } = this.props.routing;

    if (backButton && goBack) goBack();
  };

  handleBlazyCallback = state => {
    this._timer = setTimeout(() => {
      this.setState({ loaded: state });
    }, this._delay);
  };

  getSubTitleClassNames = () => {
    return clsx(_CLASSES.INNER.SUBTITLE);
  };

  getTitleClassNames = () => {
    return clsx(_CLASSES.INNER.TITLE);
  };

  getButtonClassNames = () => {
    return clsx(_CLASSES.INNER.BUTTON);
  };

  getInnerClassNames = () => {
    return clsx(_CLASSES.INNER.MAIN);
  };

  getOverlayClassNames = () => {
    return clsx(_CLASSES.OVERLAY.MAIN);
  };

  getBackgroundClassNames = () => {
    return clsx(_CLASSES.BACKGROUND.MAIN);
  };

  getBackgroundWrapClassNames = () => {
    return clsx(_CLASSES.BACKGROUND.WRAP);
  };

  getStyleClassNames = () => {
    const { loaded } = this.state;
    return clsx(_CLASSES.MAIN, loaded && _CLASSES.LOADED);
  };
}

AcHeroController.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backButton: PropTypes.bool,
  showGoogleMaps: PropTypes.number,
  urlGoogleMaps: PropTypes.string,
};

AcHeroController.defaultProps = {
  image: '',
  title: '',
  backButton: true,
};

export default AcHeroController;
