// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcHeroController from './ac-hero.controller.js';

// Imports => Atoms
import { AcContainer } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcImage from '@atoms/ac-image/ac-image.web.js';
import AcGoogleMapsLink from '@atoms/ac-google-maps-link/ac-google-maps-link.web.js';
import AcNectarSlider from '@components/ac-nectar-slider/ac-nectar-slider.web.js';

import AcButton from '@atoms/ac-button/ac-button.web.js';

@inject('routing')
@observer
class AcHero extends AcHeroController {
  render() {
    const {
      nectar = null,
      image,
      title,
      subtitle,
      backButton,
      t,
      hasOverlay = true,
      showGoogleMaps = 0,
      urlGoogleMaps,
    } = this.props;

    return (
      <div className={this.getStyleClassNames()} ref={node => (this.el = node)}>
        <div
          className={this.getBackgroundWrapClassNames()}
          ref={node => (this.background.wrp = node)}
        >
          {nectar && (
            <AcNectarSlider
              slides={nectar}
              callback={this.handleBlazyCallback}
            />
          )}
          {!nectar &&
            image &&
            image.responsive &&
            (image.responsive.xl || image.responsive.lg) && (
              <AcImage
                source={image.responsive.xl || image.responsive.lg}
                alt={image.label}
                className={this.getBackgroundClassNames()}
                callback={this.handleBlazyCallback}
                parallax={true}
                id={'ac-hero-image'}
              />
            )}
        </div>
        {hasOverlay && <div className={this.getOverlayClassNames()} />}

        <div className={this.getInnerClassNames()}>
          {backButton && (
            <AcButton
              theme={'pitch'}
              variant={'transparent'}
              className={this.getButtonClassNames()}
              callback={this.handleBackButtonCallback}
            >
              <i className="ac-icon ac-icon--chevron-left ac-button__icon ac-button__icon--left" />
              {t('Terug')}
            </AcButton>
          )}
          <AcContainer fluid={true}>
            <AcHeading rank={1} className={this.getTitleClassNames()}>
              {title}
            </AcHeading>

            <AcHeading rank={2} className={this.getSubTitleClassNames()}>
              {subtitle}
            </AcHeading>
          </AcContainer>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(AcHero);
