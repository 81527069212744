// Imports => React
import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import clsx from 'clsx';

import AcImage from '@atoms/ac-image/ac-image.web.js';

const _CLASSES = {
  MAIN: 'ac-nectar-slider',
};

// Component
const AcNectarSlider = ({ slides, callback }) => {
  let _delay = null;

  const [active, setActive] = useState(0);

  const getStyleClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  useEffect(() => {
    startNectarSlider();

    if (callback) callback(true);

    return () => clearInterval(_delay);
  });

  const startNectarSlider = useCallback(() => {
    if (_delay) clearInterval(_delay);

    const total = slides.length;

    _delay = setInterval(() => {
      let next = 0;

      if (active + 1 === total) {
        next = 0;
      } else next = active + 1;

      setActive(next);
    }, 5000);
  }, [slides, active]);

  const getActiveSlide = useMemo(() => {
    const collection = slides;
    const item = collection[active];

    const object = {
      src: item.responsive.xl,
      alt: item.label,
      id: `ac-nectar-image-${active}`,
      key: `ac-nectar-image-${active}`,
    };

    return object;
  }, [active]);

  return !slides || slides.length === 0 ? null : (
    <div className={getStyleClassNames}>
      <ReactCSSTransitionReplace
        transitionName="cross-fade"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        <div key={`ac-nectar-image-el-${active}`}>
          <img {...getActiveSlide} key={`ac-nectar-image-el-image-${active}`} />
        </div>
      </ReactCSSTransitionReplace>
    </div>
  );
};

export default memo(AcNectarSlider);
