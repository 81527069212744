// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcUserNavigationController from './ac-user-navigation.controller';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

@inject('store', 'routing')
@observer
class AcUserNavigation extends AcUserNavigationController {
  render() {
    return (
      <nav className={this.getStyleClassNames()} ref={this.$navigation}>
        <div className={this.getToggleClassNames()} onClick={this.handleToggle}>
          <AcIcon
            icon={'account-outline'}
            className={this.getToggleIconClassNames()}
          />
          <span className={this.getToggleLabelClassNames()} />
          <AcIcon
            icon={'chevron-down'}
            className={this.getPointerIconClassNames()}
          />
          <AcRipple size={'small'} simple />
        </div>

        <ul className={this.getListClassNames()}>
          <li className={this.getListItemClassNames()}>
            <div
              className={this.getLinkClassNames()}
              onClick={event => {
                this.handleClick(event, '/documents');
              }}
            >
              <AcIcon
                icon={'file-document-outline'}
                className={this.getLinkIconClassNames()}
              />
              Mijn documenten
              <AcRipple size={'small'} simple />
            </div>
          </li>
          <li className={this.getListItemClassNames()}>
            <div
              className={this.getLinkClassNames()}
              onClick={this.handleLogout}
            >
              <AcIcon
                icon={'logout-variant'}
                className={this.getLinkIconClassNames()}
              />
              {this.props.t('Uitloggen')}
              <AcRipple size={'small'} simple />
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withNamespaces()(AcUserNavigation);
