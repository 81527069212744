// Imports => React
import React from 'react';

// Imports => Controller
import AcStepperController from './ac-stepper.controller.js';

// Imports => Atoms
import AcStep from '@atoms/ac-step/ac-step.web.js';

// Component
class AcStepper extends AcStepperController {
  render() {
    const { steps, size, indexes, horizontal } = this.props;
    const { divider } = this.state;

    return (
      <div className={this.getStyleClassNames()} ref={node => (this.el = node)}>
        {steps.map((step, index) => {
          const next = index + 1 >= steps.length ? false : steps[index + 1];

          if (next) {
            step.connected =
              (step.current || step.finished) &&
              (next.current || next.finished);
          }

          return (
            <AcStep
              key={index}
              {...step}
              size={step.size ? step.size : size}
              divider={{
                width: divider.styles[index] && divider.styles[index].width,
                marginLeft:
                  divider.styles[index] && divider.styles[index].offset,
              }}
              number={step.number ? step.number : index + 1}
              indexes={indexes}
              horizontal={horizontal}
              total={steps.length}
              callback={this.handleCallback}
            />
          );
        })}
      </div>
    );
  }
}

export default AcStepper;
