// Imports => React
import React from 'react';
import clsx from 'clsx';

import AcUUID from '@utils/ac-uuid.js';

import AcIcon from '@atoms/ac-icon/ac-icon.web.js';

const _CLASSES = {
  MAIN: 'ac-header',
  LIGHT: 'ac-header--light',
  DARK: 'ac-header--dark',
  ALPHA: 'ac-header--alpha',
  TRANSPARENT: 'ac-header--transparent',
  CONTAINER: {
    MAIN: 'ac-header__container',
  },
  BLOCK: {
    MAIN: 'ac-header__block',
    NOPADDING: 'ac-header__block--no-padding',
    LEFT: 'ac-header__block--left',
    CENTER: 'ac-header__block--center',
    RIGHT: 'ac-header__block--right',
    BORDERED: 'ac-header__block--bordered',
  },
  ICON: 'ac-header__icon',
};

class AcHeaderController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.changeLanguage = this.changeLanguage.bind(this);
    this.logout = this.logout.bind(this);
    this.changeAccount = this.changeAccount.bind(this);
    this.handleHamburgerCallback = this.handleHamburgerCallback.bind(this);

    this.state = {
      hamburger: {
        open: false,
      },
      routes: {
        main: [
          {
            id: AcUUID(),
            path: '/',
            label: 'Overzicht',
            title: 'Overzicht',
            ref: false,
            children: null,
          },
          {
            id: AcUUID(),
            path: '/portfolio',
            label: 'Portfolio',
            title: 'Portfolio',
            ref: false,
            children: 'project',
          },
          {
            id: AcUUID(),
            path: '/news',
            label: 'Nieuws',
            title: 'Nieuws',
            ref: false,
            children: 'news',
            lang: 'nl',
          },
          {
            id: AcUUID(),
            path: '/faq',
            label: 'FAQ',
            title: 'FAQ',
            ref: false,
            children: null,
            lang: 'nl',
          },
        ],
        user: [
          {
            id: AcUUID(),
            path: '/account',
            label: (
              <AcIcon
                icon={'account-outline'}
                className={this.getIconClassNames()}
              />
            ),
            title: 'Mijn Gegevens',
            className: 'user',
            ref: false,
          },
        ],
        controls: [
          {
            id: AcUUID(),
            callback: this.logout,
            label: (
              <AcIcon
                icon={'logout-variant'}
                className={this.getIconClassNames()}
              />
            ),
            title: 'Uitloggen',
            className: 'logout',
            ref: false,
          },
        ],
      },
    };
  }

  getRoutes(_key) {
    const { routes } = this.state;
    const { language } = this.store.settings;

    let result = _key && routes[_key] ? routes[_key] : false;

    if (result) {
      result = result.filter(r => {
        if (!r.lang) {
          return true;
        } else {
          return r.lang === language;
        }
      });
    }

    return result;
  }

  async changeAccount(account) {
    const { store, routing } = this.props;

    await store.profile.setAccount(account.id).then(async () => {
      await store.auth.whoami();
      const { location } = routing;

      if (location.pathname.indexOf('/project/') > -1) {
        if (routing.push) routing.push('/portfolio');
      }
    });
  }

  async changeLanguage(language) {
    const { store } = this.props;

    await store.settings.setLanguage(language);
  }

  async logout() {
    const { store } = this.props;

    await store.auth.logout();
  }

  handleHamburgerCallback(event) {
    if (event) event.preventDefault();
    if (event) event.stopPropagation();

    const { store } = this.props;

    store.ui.setState('navigation', 'visible', !store.ui.navigation.visible);
  }

  getIconClassNames() {
    return clsx(_CLASSES.ICON);
  }

  getBlockClassModifiers(modifiers) {
    return modifiers.map(item => {
      return _CLASSES.BLOCK[item.toUpperCase().replace('-', '')];
    });
  }

  getBlockClassNames(modifiers, classes) {
    return clsx([
      _CLASSES.BLOCK.MAIN,
      modifiers && this.getBlockClassModifiers(modifiers),
      classes && classes,
    ]);
  }

  getContainerClassNames() {
    return clsx([_CLASSES.CONTAINER.MAIN]);
  }

  getStyleClassNames() {
    const { theme } = this.props;

    return clsx([_CLASSES.MAIN, theme && _CLASSES[theme.toUpperCase()]]);
  }
}

AcHeaderController.propTypes = {};

AcHeaderController.defaultProps = {};

export default AcHeaderController;
