// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-switch',
  LIGHT: 'ac-switch--light',
  DARK: 'ac-switch--dark',
  ALPHA: 'ac-switch--alpha',
  SLOT: {
    MAIN: 'ac-switch__slot',
  },
  TRACKER: {
    MAIN: 'ac-switch__tracker',
    LEFT: 'ac-switch__tracker--move-left',
    RIGHT: 'ac-switch__tracker--move-right',
  },
  OPTION: {
    MAIN: 'ac-switch__option',
    SELECTED: 'ac-switch__option--selected',
    INPUT: 'ac-switch__input',
    PLACEHOLDER: 'ac-switch__placeholder',
    LABEL: 'ac-switch__label',
  },
};

// Controller
class AcSwitchController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _CLASSES: _CLASSES,
      value: props.value,
      switch: null,
      selected: 0,
      tracker: {
        el: null,
        direction: 'right',
        position: {
          left: 0,
          right: 'auto',
        },
      },
    };
  }

  componentDidMount() {
    this.setInitialPosition();
  }

  setInitialPosition = () => {
    const { options } = this.props;
    let initialSelection = false;

    options.forEach((option, i) => {
      option.index = i;

      if (this.isChecked(option)) {
        initialSelection = option;
      }
    });

    if (!initialSelection) initialSelection = options[0];

    this.handleChangeEvent(false, initialSelection, true);
  };

  handleChangeEvent = (event, option, initial) => {
    const { name, callback } = this.props;
    const { value, selected, tracker } = this.state;

    if (event && event.persist) event.persist();

    if (value === option.value && !initial) return;

    const direction = selected > option.index ? 'left' : 'right';

    this.setState(
      {
        value: option.value,
        selected: option.index,
        tracker: {
          ...tracker,
          direction,
        },
      },
      () => {
        this.moveTracker(option.el);

        if (callback && event) callback(event, name, this.state.value);
      }
    );
  };

  moveTracker = $option => {
    const { el, tracker } = this.state;

    const offset = el.getBoundingClientRect();
    const rect = $option.getBoundingClientRect();

    this.setState({
      tracker: {
        ...tracker,
        position: {
          left: Math.round(rect.left - offset.left) + 'px',
          right: Math.round(offset.right - rect.right) + 'px',
        },
      },
    });
  };

  isChecked = option => {
    const { value } = this.state;
    return value === option.value;
  };

  getOptionClassNames = option => {
    const { value } = this.state;
    return clsx(
      _CLASSES.OPTION.MAIN,
      value === option.value && _CLASSES.OPTION.SELECTED
    );
  };

  getTrackerClassNames = () => {
    const { tracker } = this.state;

    return clsx(
      _CLASSES.TRACKER.MAIN,
      tracker.direction && _CLASSES.TRACKER[tracker.direction.toUpperCase()]
    );
  };

  getSlotClassNames = () => {
    return clsx(_CLASSES.SLOT.MAIN);
  };

  getStyleClassNames = () => {
    const { theme } = this.props;

    return clsx(_CLASSES.MAIN, theme && _CLASSES[theme.toUpperCase()]);
  };

  getTrackerPosition = () => {
    const { tracker } = this.state;

    return {
      left: tracker.position.left,
      right: tracker.position.right,
    };
  };
}

AcSwitchController.propTypes = {
  theme: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  options: PropTypes.array,
  callback: PropTypes.func,
};

AcSwitchController.defaultProps = {
  theme: 'default',
  value: null,
  options: [],
  callback: () => {},
};

export default AcSwitchController;
