// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Imports => Utils
import StrengthIndicator from '@utils/ac-password-strength.js';

const _CLASSES = {
  MAIN: 'ac-strength-indicator',
  TRACKER: {
    WRP: 'ac-strength-indicator__tracker-wrp',
    MAIN: 'ac-strength-indicator__tracker',
    UNDEFINED: 'ac-strength-indicator__tracker--undefined',
    VERY_WEAK: 'ac-strength-indicator__tracker--very-weak',
    WEAK: 'ac-strength-indicator__tracker--weak',
    SO_SO: 'ac-strength-indicator__tracker--so-so',
    GOOD: 'ac-strength-indicator__tracker--good',
    VERY_STRONG: 'ac-strength-indicator__tracker--very-strong',
  },
  MESSAGE: {
    MAIN: 'ac-strength-indicator__message',
    UNDEFINED: 'ac-strength-indicator__message--undefined',
    VERY_WEAK: 'ac-strength-indicator__message--very-weak',
    WEAK: 'ac-strength-indicator__message--weak',
    SO_SO: 'ac-strength-indicator__message--so-so',
    GOOD: 'ac-strength-indicator__message--good',
    VERY_STRONG: 'ac-strength-indicator__message--very-strong',
  },
};

class AcStrengthIndicatorController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.getCleanedMessage = this.getCleanedMessage.bind(this);
    this.getStrengthIndicatorMessage = this.getStrengthIndicatorMessage.bind(
      this
    );
    this.getStrengthIndicatorLevel = this.getStrengthIndicatorLevel.bind(this);
  }

  getCleanedMessage() {
    const { value } = this.props;

    const indicator = StrengthIndicator(value);
    const level = this.getStrengthIndicatorMessage(indicator);

    return level;
  }

  getStrengthIndicatorMessage(strength) {
    let msg = '';

    switch (strength) {
      default:
      case 0:
        msg = '';
        break;

      case 1:
        msg = 'Heel zwak';
        break;

      case 2:
        msg = 'Zwak';
        break;

      case 3:
        msg = 'Middelmatig';
        break;

      case 4:
        msg = 'Goed';
        break;

      case 5:
        msg = 'Heel sterk';
        break;
    }

    return msg;
  }

  getStrengthIndicatorLevel(strength) {
    let level = '';

    switch (strength) {
      default:
      case 0:
        level = 'undefined';
        break;

      case 1:
        level = 'very-weak';
        break;

      case 2:
        level = 'weak';
        break;

      case 3:
        level = 'so-so';
        break;

      case 4:
        level = 'good';
        break;

      case 5:
        level = 'very-strong';
        break;
    }

    return level;
  }

  getMessageClassNames() {
    const { value } = this.props;

    const indicator = StrengthIndicator(value);
    const level = this.getStrengthIndicatorLevel(indicator);

    return clsx(
      _CLASSES.MESSAGE.MAIN,
      level && _CLASSES.MESSAGE[level.replace('-', '_').toUpperCase()]
    );
  }

  getTrackerClassNames() {
    const { value } = this.props;

    const indicator = StrengthIndicator(value);
    const level = this.getStrengthIndicatorLevel(indicator);

    return clsx(
      _CLASSES.TRACKER.MAIN,
      level && _CLASSES.TRACKER[level.replace('-', '_').toUpperCase()]
    );
  }

  getTrackerWrpClassNames() {
    return clsx(_CLASSES.TRACKER.WRP);
  }

  getStyleClassNames() {
    const { className } = this.props;
    return clsx(_CLASSES.MAIN, className && className);
  }
}

AcStrengthIndicatorController.propTypes = {
  value: PropTypes.string,
};

AcStrengthIndicatorController.defaultProps = {
  value: '',
};

export default AcStrengthIndicatorController;
