// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-navigation',
  VISIBLE: 'ac-navigation--visible',
  NAVLIST: {
    MAIN: 'ac-navigation__list',
  },
  NAVLISTITEM: {
    MAIN: 'ac-navigation__item',
  },
  NAVLINK: {
    MAIN: 'ac-navigation__link',
    ACTIVE: 'ac-navigation__link--active',
  },
  INDICATOR: {
    MAIN: 'ac-navigation__indicator',
    STATIC: 'ac-navigation__indicator--static',
    LEFT: 'ac-navigation__indicator--move-left',
    RIGHT: 'ac-navigation__indicator--move-right',
  },
};

class AcNavigationController extends React.Component {
  constructor(props) {
    super(props);

    this._unsubscribeFromStore = null;
    this.store = props.store;

    this.state = {
      el: false,
      indicator: {
        el: false,
        direction: 'right',
        position: {
          left: 0,
          right: 'auto',
        },
      },
      blockIndicatorAnimation: false,
      active: 0,
      routes: props.routes,
    };

    this.init = this.init.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.handleClickEvent = this.handleClickEvent.bind(this);
    this.resetIndicator = this.resetIndicator.bind(this);
    this.moveIndicator = this.moveIndicator.bind(this);
  }

  componentDidMount() {
    const { history } = this.props.routing;

    this._unsubscribeFromStore = history.subscribe((location, action) =>
      this.subscribe(location, action)
    );

    this.init();
  }

  componentWillUnmount() {
    this._unsubscribeFromStore();
  }

  componentDidUpdate(props) {
    if (props.lng !== props.i18n.language) {
      const { active, routes } = this.state;
      const currentRoute = routes[active || 0];

      this.calculateIndicator(currentRoute, true);
    }
  }

  init() {
    const { routes } = this.state;

    routes.map((route, i) => {
      route.index = i;

      return route;
    });

    this.setState({ routes });
  }

  subscribe(location) {
    const { routes } = this.state;

    let found = false;

    routes.forEach(route => {
      if (
        (route.path === location.pathname) |
        (route.children === location.pathname.split('/')[1])
      ) {
        found = true;
        this.handleClickEvent({}, route);
      }
    });

    if (!found) {
      this.resetIndicator();
    }
  }

  isActive(match, location) {
    return match !== null ? match.url === location.pathname : false;
  }

  handleClickEvent(event, route) {
    this.calculateIndicator(route);
  }

  resetIndicator() {
    if (!this.props.indicate) return;
    this.moveIndicator({ left: '100%', right: 0 }, 'right');
  }

  calculateIndicator(route, blockIndicatorAnimation = false) {
    if (!this.props.indicate) return;
    const { el, active } = this.state;

    const offset = el.getBoundingClientRect();
    const rect = route.ref.getBoundingClientRect();

    const target = {
      left: rect.left - offset.left,
      right: offset.right - rect.right,
    };

    let direction =
      active > route.index ? 'left' : active < route.index ? 'right' : 'left';

    this.setState(
      {
        active: route.index,
        blockIndicatorAnimation,
      },
      () => {
        this.moveIndicator(target, direction);
      }
    );
  }

  moveIndicator(target, direction) {
    let { indicator } = this.state;

    indicator.position = {
      left: target.left,
      right: target.right,
    };
    indicator.direction = direction;

    this.setState({
      indicator,
    });
  }

  getIndicatorClassNames() {
    const { indicator, blockIndicatorAnimation } = this.state;

    return clsx(
      _CLASSES.INDICATOR.MAIN,
      blockIndicatorAnimation && _CLASSES.INDICATOR.STATIC,
      indicator.direction &&
        _CLASSES.INDICATOR[indicator.direction.toUpperCase()]
    );
  }

  getNavLinkClassNames(route, active) {
    return clsx(
      _CLASSES.NAVLINK.MAIN,
      active && _CLASSES.NAVLINK.ACTIVE,
      route.className && `${_CLASSES.NAVLINK.MAIN}--${route.className}`
    );
  }

  getNavListItemClassNames() {
    return clsx(_CLASSES.NAVLISTITEM.MAIN);
  }

  getNavListClassNames() {
    return clsx(_CLASSES.NAVLIST.MAIN);
  }

  getStyleClassNames() {
    const { className } = this.props;
    const { navigation } = this.store.ui;

    return clsx(
      _CLASSES.MAIN,
      navigation && navigation.visible && _CLASSES.VISIBLE,
      className && className
    );
  }
}

AcNavigationController.propTypes = {
  indicate: PropTypes.bool,
};

AcNavigationController.defaultProps = {
  indicate: true,
};

export default AcNavigationController;
