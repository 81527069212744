// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { inject, observer } from 'mobx-react';

// Imports => Controller
import AcHeaderController from './ac-header.controller';

// Imports => Components
import AcNavigation from '@components/ac-navigation/ac-navigation.web.js';
import AcUserNavigation from '@components/ac-user-navigation/ac-user-navigation.web.js';

// Imports => Atoms
import AcLogo from '@atoms/ac-logo/ac-logo.web.js';
import AcAccountSelector from '@atoms/ac-account-selector/ac-account-selector.web.js';
import AcLanguageSelector from '@atoms/ac-language-selector/ac-language-selector.web.js';
import AcHamburger from '@atoms/ac-hamburger/ac-hamburger.web.js';

import AcImpersonateBar from '@components/ac-impersonate-bar/ac-impersonate-bar.web';

@inject('routing', 'store')
@observer
class AcHeader extends AcHeaderController {
  render() {
    const { store } = this.props;
    const { language, languages } = store.settings;
    const { user, impersonated } = store.auth;

    const navigation = {
      main: this.getRoutes('main'),
      user: this.getRoutes('user'),
      controls: this.getRoutes('controls'),
    };

    return (
      <header
        className={this.getStyleClassNames()}
        id={this.getStyleClassNames()}
        key={`rea-header-locale-${language}`}
      >
        <div className={this.getContainerClassNames()}>
          {store.auth.isAuthorized && (
            <div className={this.getBlockClassNames(['left'])}>
              <AcLogo />
            </div>
          )}

          {store.auth.isAuthorized && navigation.main && (
            <div className={this.getBlockClassNames(['center', 'no-padding'])}>
              <AcNavigation routes={navigation.main} />
            </div>
          )}
          {store.auth.isAuthorized && store.auth.user && (
            <div
              className={this.getBlockClassNames(
                [
                  store.auth.isAuthorized ? 'right' : 'center',
                  'bordered',
                  'no-padding',
                ],
                'h-hidden-xxs h-hidden-xs h-hidden-sm'
              )}
            >
              <AcAccountSelector
                preferredAccount={user.preferred_account}
                accounts={user.accounts}
                callback={this.changeAccount}
              />
            </div>
          )}
					<div
						className={this.getBlockClassNames(
							[store.auth.isAuthorized ? 'right' : 'center', 'bordered'],
							'h-hidden-xxs h-hidden-xs h-hidden-sm'
						)}
					>
						<AcLanguageSelector
							currentLanguage={language}
							languages={languages}
							theme={!store.auth.isAuthorized ? 'pitch' : 'default'}
							callback={this.changeLanguage}
						/>
					</div>
          {store.auth.isAuthorized && <AcUserNavigation />}
          {store.auth.isAuthorized && (
            <div
              className={this.getBlockClassNames(
                ['right'],
                'h-hidden-lg h-hidden-xl h-padding-x-25'
              )}
            >
              <AcHamburger
                open={store.ui.navigation.visible}
                callback={this.handleHamburgerCallback}
              />
            </div>
          )}
        </div>
        {impersonated && (
          <AcImpersonateBar
            impersonatingUser={user}
            exitImpersonation={this.logout}
          />
        )}
      </header>
    );
  }
}

export default withNamespaces()(AcHeader);
