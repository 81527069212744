// Imports => React
import React from 'react';

// Imports => Controller
import AcLightBoxController from './ac-light-box.controller';

// Imports => Atoms
import AcCard from '@atoms/ac-card/ac-card.web';
import { AcColumn } from '@atoms/ac-grid';

class AcLightBox extends AcLightBoxController {
  render() {
    const { hasNavigation } = this.state;

    const buildLightBox = this.buildLightBox();
    const content = this.getContent();

    return (
      <div
        className={this.getWrapperStyleClassNames()}
        ref={this.setWrapperRef}
      >
        {buildLightBox && (
          <div
            className={this.getStyleClassNames()}
            ref={node => (this.el = node)}
            onClick={this.closeLightBox}
          >
            <div className={this.getCloseIconClassNames()}>
              <i
                className={this.getIconClassNames('close')}
                onClick={this.closeLightBox}
              />
            </div>

            <div className={this.getContentClassNames()}>
              <AcColumn
                xs={{ size: 10 }}
                className={this.getContentItemClassNames()}
              >
                <AcCard
                  hoverAnimation={false}
                  onClick={this.goToNextItem}
                  theme={'transparent'}
                  shadow={false}
                >
                  {content}
                </AcCard>
              </AcColumn>
              {hasNavigation && (
                <button
                  className={this.getNavigationItemClassNames('previous')}
                  onClick={this.goToPreviousItem}
                >
                  <i className={this.getIconClassNames('arrow_left')} />
                </button>
              )}
              {hasNavigation && (
                <button
                  className={this.getNavigationItemClassNames('next')}
                  onClick={this.goToNextItem}
                >
                  <i className={this.getIconClassNames('arrow_right')} />
                </button>
              )}
            </div>
            {hasNavigation && (
              <div className={this.getFooterClassNames()}>
                <div className={this.getItemCounterClassNames()}>
                  {this.getItemCounterText()}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AcLightBox;
