// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-toaster',
  PROGRESS: 'ac-toaster__progress',
  TYPES: {
    DEFAULT: 'ac-toaster--default',
    INFO: 'ac-toaster--info',
    SUCCESS: 'ac-toaster--success',
    WARNING: 'ac-toaster--warning',
    ERROR: 'ac-toaster--error',
  },
  BODY: 'ac-toaster__body',
  ICON: {
    MAIN: 'ac-icon ac-toaster__icon',
    WRP: 'ac-toaster__icon-wrp',
    DEFAULT: 'ac-icon--message-text-outline',
    INFO: 'ac-icon--information-outline',
    SUCCESS: 'ac-icon--check-circle-outline',
    WARNING: 'ac-icon--alert-outline',
    ERROR: 'ac-icon--alert-circle-outline',
  },
  CONTENT: {
    MAIN: 'ac-toaster__content',
    TITLE: 'ac-toaster__title',
    DESCRIPTION: 'ac-toaster__description',
    CODE: 'ac-toaster__code',
  },
  CLOSE: {
    ICON: 'ac-icon ac-icon--close ac-toaster__close-icon',
    WRP: 'ac-toaster__close-icon-wrp',
  },
};

class AcToasterController extends React.Component {
  constructor(props) {
    super(props);

    this.interval = null;
    this.store = props.store;

    this.state = {};

    this.startCountdown = this.startCountdown.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.startCountdown();
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  startCountdown() {
    const { expires, id } = this.props;

    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      const now = new Date().getTime();

      if (now >= expires) {
        if (this.interval) clearInterval(this.interval);
        this.store.toasters.remove(id);
      }
    }, 1000);
  }

  close() {
    const { id } = this.props;
    if (this.interval) clearInterval(this.interval);
    this.store.toasters.remove(id);
  }

  getCloseIconClassNames() {
    return clsx(_CLASSES.CLOSE.ICON);
  }

  getCloseWrpClassNames() {
    return clsx(_CLASSES.CLOSE.WRP);
  }

  getCodeClassNames() {
    return clsx(_CLASSES.CONTENT.CODE);
  }

  getDescriptionClassNames() {
    return clsx(_CLASSES.CONTENT.DESCRIPTION);
  }

  getTitleClassNames() {
    return clsx(_CLASSES.CONTENT.TITLE);
  }

  getContentWrpClassNames() {
    return clsx(_CLASSES.CONTENT.MAIN);
  }

  getIconClassNames() {
    const { variant } = this.props;

    return clsx(
      _CLASSES.ICON.MAIN,
      variant && _CLASSES.ICON[variant.toUpperCase()]
    );
  }

  getIconWrpClassNames() {
    return clsx(_CLASSES.ICON.WRP);
  }

  getBodyClassNames() {
    return clsx(_CLASSES.BODY);
  }

  getProgressClassNames() {
    return clsx(_CLASSES.PROGRESS);
  }

  getStyleClassNames() {
    const { variant } = this.props;

    return clsx(
      _CLASSES.MAIN,
      variant && _CLASSES.TYPES[variant.toUpperCase()]
    );
  }

  getStyleProperties() {
    const { delay } = this.props;

    return {
      transitionDuration: delay ? delay + 'ms' : '10000ms',
    };
  }
}

AcToasterController.propTypes = {
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error']),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  closeable: PropTypes.bool,
};

AcToasterController.defaultProps = {
  variant: 'default',
  closeable: true,
};

export default AcToasterController;
